import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Reviews from './Reviews';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import CouponModalTrigger from '../modals/CouponModalTrigger';
import features from '../../utils/features';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function Cart() {
    const history = useHistory();
    const location = useLocation();
    const { type, id } = useParams();

    const { products, quantity, total } = useCart();

    const { t } = useTranslation();
    const { restaurant } = useRestaurant();

    const [coupon, setCoupon] = useState();
    const [newtotal, setNewTotal] = useState();
    const [couponSentinel, setCouponSentinel] = useState();

    const onClickHandler = () => {
        if (type == "r" || type == "h" || type == "s") {
            history.push(`${location.pathname}/tables`);
        } else if (type == "d" || type == "t") {
            history.push(`${location.pathname}/type`);
        } else if (type == "b") {
            history.push(`/${type}/q/${restaurant.id}/bookingrestaurant`);
        }
    }

    const onBackHandler = () => history.push(`/${type}/q/${id}/menu`);

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    useEffect(() => {
        function checkCoupon() {
            let coupon = sessionStorage.getItem("coupon");
            if (coupon) {
                coupon = JSON.parse(coupon);
                console.log(coupon);
                if (coupon.restaurantAccepted == restaurant.id) {
                    console.log("setto coupon")
                    setCoupon(coupon);
                    setNewTotal(formats.formatPercentageToPrice(coupon.discount, total));
                }
            }
        }

        checkCoupon();
    }, [total, couponSentinel]);

    return (
        <div className="cart pb-48">
            <div className="flex items-center border-b border-b-grey-200">
                <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>

                <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("cart.h2_1")}</h2>
            </div>

            <p className="p-4 px-10 text-center">{t("cart.p_2")}</p>

            <Reviews products={products} />

            <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">
                {coupon && coupon.accepted == true ?
                    <p className="flex flex-col px-4 py-3">
                        <span>Totale: {formats.formatPrice(total)}</span>
                        <span>Valore coupon: {coupon.discount}%</span>
                        <span className="text-mycia-green">Totale scontato: <span className="font-bold">{formats.formatPrice(newtotal)}</span></span>
                    </p>
                    :
                    <p className="flex justify-between px-4 py-3"><span>{t("cart.p_1.span_1")}</span> <span className="font-bold">{formats.formatPrice(total)}</span></p>
                }

                {quantity ?
                    <button type="button" onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green">{t("cart.button_1")}</button> :
                    <button type="button" onClick={onBackHandler} className="block py-2 rounded-full bg-grey-200">{t("cart.button_2")}</button>}

                {restaurant.has_coupons ? (type != "r" ? <CouponModalTrigger setCouponSentinel={setCouponSentinel} /> : null) : null}

            </div>
        </div>
    );
}

export default Cart;
