import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import { useCart } from "../../utils/contexts/cart-context";
import client from '../../logic/stripe';
import { useTranslation } from "react-i18next";

function StripeWidgetContent(props) {
    const { order } = props;

    const { type, id } = useParams();
    const history = useHistory();
    const { type: orderType } = useCart();
    const { restaurant } = useRestaurant();

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState("");
    const [total, setTotal] = useState();
    const [paymentRequest, setPaymentRequest] = useState(null);

    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();

    useEffect(() => {
        initStripePayment();

        async function initStripePayment() {
            setTotal(order.order.total);

            // Create PaymentIntent as soon as the page loads
            const payload = {
                "amount": order.order.total * 100,
                "currency": "eur",
                "description": JSON.stringify({ ris_id: order.restaurant.id, amount: order.order.total }),
            }

            const res = await client.createCheckout(payload);
            if (res) {
                setClientSecret(res.client_secret);
            } else {
                setError("Errore nell'elaborazione del pagamento, riprova più tardi");
            }
        }

    }, []);

    useEffect(() => {
        if (!stripe || !elements || !clientSecret) {
            return;
        }

        const pr = stripe.paymentRequest({
            country: "IT",
            currency: "eur",
            total: {
                label: restaurant.name,
                amount: order.order.total * 100,
            }
        });

        pr.canMakePayment().then(result => {
            if (result) {
                setPaymentRequest(pr);
            }
        });
    }, [stripe, elements, clientSecret])

    const cardStyle = {
        style: {
            base: {
                color: '#303238',
                fontSize: '20px',
                fontFamily: '"Nunito", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
        showIcon: true,
    };

    const cardNumber = {
        style: {
            base: {
                color: '#303238',
                fontSize: '20px',
                fontFamily: '"Nunito", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
        showIcon: true,
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const onClickPaymentRequest = () => {
        paymentRequest.on("paymentmethod", async (event) => {
            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: event.paymentMethod.id,
            }, { handleActions: false });

            if (paymentIntent.status === "requires_action") {
                stripe.confirmCardPayment(clientSecret);
            }

            if (stripeError) {
                setError(stripeError.message)
                event.complete("fail");
            } else {
                event.complete("success");
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                if (type == "t" || type == "d") {
                    history.push(`/${type}/q/${id}/orders/awaitsms/?provider=stripe&transactionid=${paymentIntent.id}`);
                }
                if (type == "r") {
                    history.push(`/${type}/q/${id}/orders/success/?provider=stripe&transactionid=${paymentIntent.id}`);
                }
            }
        });
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            if (type == "t" || type == "d") {
                history.push(`/${type}/q/${id}/orders/awaitsms/?provider=stripe&transactionid=${payload.paymentIntent.id}`);
            }
            if (type == "r") {
                history.push(`/${type}/q/${id}/orders/success/?provider=stripe&transactionid=${payload.paymentIntent.id}`);
            }
        }
    };

    return (
        <div>
            <form className="relative px-3" onSubmit={handleSubmit}>
                <div className="pb-3">
                    <p className="text-xl">Numero carta</p>
                    <div className="p-3 border border-grey-400 rounded-lg">
                        <CardNumberElement
                            options={cardNumber}
                            onReady={() => {
                                console.log("CardNumberElement [ready]");
                            }}
                            onChange={handleChange}
                            onBlur={() => {
                                console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                                console.log("CardNumberElement [focus]");
                            }}
                        />
                    </div>
                </div>

                <div className="py-3">
                    <p className="text-xl">Data di scadenza</p>
                    <div className="p-3 border border-grey-400 rounded-lg">
                        <CardExpiryElement
                            options={cardStyle}
                            onReady={() => {
                                console.log("CardNumberElement [ready]");
                            }}
                            onChange={handleChange}
                            onBlur={() => {
                                console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                                console.log("CardNumberElement [focus]");
                            }}
                        />
                    </div>
                </div>

                <div className="py-3">
                    <p className="text-xl">CVC</p>
                    <div className="p-3 border border-grey-400 rounded-lg">
                        <CardCvcElement
                            options={cardStyle}
                            onReady={() => {
                                console.log("CardNumberElement [ready]");
                            }}
                            onChange={handleChange}
                            onBlur={() => {
                                console.log("CardNumberElement [blur]");
                            }}
                            onFocus={() => {
                                console.log("CardNumberElement [focus]");
                            }}
                        />
                    </div>
                </div>

                <p className="text-red-600">{error}</p>

                <div className="fixed bottom-0 left-0 w-full mt-8 p-4 pt-1 border-t-2 bg-white">
                    <div className="flex py-3 items-center">
                        <input type="checkbox" id="agreements" name="agreements" required />
                        <p className="text-xs pl-2">{t("formtable.lable_1.span_1")} <a target="_blank" className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/">{t("formtable.lable_1.a_1")}</a></p>
                    </div>

                    <p>Totale: {total}€</p>

                    {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} onClick={onClickPaymentRequest} />}

                    <button className="w-full mt-4 py-2 rounded-full bg-mycia-green" type="submit" disabled={!stripe}>PAGA</button>
                </div>
            </form>
        </div>
    );
}

export default StripeWidgetContent;
